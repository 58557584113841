import React from "react";
import Select, { components } from "react-select";
import { FieldProps } from "formik";
import { SalonBranchModel } from "@/models";
import styled from "styled-components";

export const SelectWrapStyled = styled.div`
  width: 796px;
  height: 105px;
  border: 0.75px solid #dee2ea;
  border-radius: 12px;
  margin-bottom: 40px;
  padding: 17px 30px 17px 22px;
`;

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        src="/statics/icons/drop.svg"
        alt=""
        className="w-[24px] h-[12px] mb-[22px]"
      />
    </components.DropdownIndicator>
  );
};

const customStyles = {
  control: (base: any) => ({
    ...base,
    border: "0 !important",
    boxShadow: "0 !important",
    "&:hover": {
      border: "0 !important",
    },
  }),
};

export interface SelectFieldProps extends FieldProps {
  label?: string | JSX.Element;
  placeholder?: string;
  disable?: boolean;
  options?: SalonBranchModel[];
}

export function SelectField(props: SelectFieldProps) {
  const { field, form, label, placeholder, disable, options } = props;
  const { name, value } = field;
  const { errors, touched } = form;
  const showError = errors[name] && touched[name];

  const newOptions = options?.map((item) => ({
    label: item?.address ?? item?.name,
    value: item?.id,
  }));

  const selectedOption = newOptions?.find((option) => option.value === value);

  const handleSelectedOptionChange = (selectedOption: any) => {
    const selectedValue = selectedOption
      ? selectedOption.value
      : selectedOption;

    const changeEvent = {
      target: {
        name: name,
        value: selectedValue,
      },
    };
    field.onChange(changeEvent);
  };

  return (
    <SelectWrapStyled>
      {label && (
        <h5 className="text-[#405275] ml-[8px] mb-0 leading-6">{label}</h5>
      )}

      <Select
        id={name}
        {...field}
        value={selectedOption}
        onChange={handleSelectedOptionChange}
        styles={customStyles}
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        isSearchable={false}
        placeholder={placeholder}
        isDisabled={disable}
        options={newOptions}
        className="text-3xl font-bold text-primary p-0 cursor-pointer"
      />

      {showError && (
        <h4 className="mt-[18px] text-[#FF0000] ml-[-18px] text-xl">
          {errors[name] as string}
        </h4>
      )}
    </SelectWrapStyled>
  );
}
