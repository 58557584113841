import React, { useState } from "react";
import { Input } from "antd";
import styled from "styled-components";
import { IInputFieldProps } from "./InputField";
import Image from "next/image";

const InputWrapStyled = styled.div`
  width: 796px;
  height: 105px;
  border: 0.75px solid #dee2ea;
  border-radius: 12px;
  margin-bottom: 40px;
  padding: 18px 30px;
  position: relative;
`;
const InputStyled = styled(Input)`
  border: none !important;
  padding: 0 !important;
  outline: none !important;
  margin-top: 4px;
  font-size: 1.875rem;
  color: #2a3345;
  font-weight: 700;
  line-height: 47px;


  &::placeholder {
    color: #808b9f;
  }

  &:focus {
    border: none !important;
    box-shadow: 0 0 #0000 !important;
  }
`;

export function InputPassWordField(props: IInputFieldProps) {
  const { field, form, label, placeholder, disable } = props;
  const [isShow, setIsShow] = useState(false);

  const { name } = field;
  const { errors, touched } = form;
  const showError = errors[name] && touched[name];
  const errorString = errors[name] as string;

  return (
    <InputWrapStyled>
      {label && <h5 className="text-[#405275] leading-6 mb-0">{label}</h5>}
      <InputStyled
        {...field}
        id={name}
        name={name}
        placeholder={placeholder}
        disabled={disable}
        type={isShow ? "text" : "password"}
      />
      <span
        onClick={() => setIsShow(!isShow)}
        className="absolute right-[30px] bottom-[26px] cursor-pointer"
      >
        <Image
          src={
            isShow
              ? "/statics/icons/show-password.svg"
              : "/statics/icons/hide-password.svg"
          }
          alt="salon-img"
          width={36}
          height={30}
        />
      </span>

      {showError && (
        <h4 className="mt-[10px] text-[#FF0000] ml-[-18px] text-xl">
          {errorString}
        </h4>
      )}
    </InputWrapStyled>
  );
}
