import React, { useState } from "react";
import { ModalDatePicker, PrimaryButton } from "@/components";
import { Input } from "antd";
import { FieldProps } from "formik";
import styled from "styled-components";
import Image from "next/image";
interface IInputFieldprops extends FieldProps {
  label?: string | JSX.Element;
  placeholder?: string;
  disable?: boolean;
}

const InputWrapStyled = styled.div`
  width: 796px;
  height: 105px;
  border: 0.75px solid #dee2ea;
  border-radius: 12px;
  margin-bottom: 40px;
  padding: 18px 30px;
`;
const InputStyled = styled(Input)`
  border: none !important;
  padding: 0 !important;
  outline: none !important;
  margin-top: 4px;
  font-size: 1.875rem;
  color: #2a3345;
  font-weight: 700;

  &::placeholder {
    color: #808b9f;
  }

  &:focus {
    border: none !important;
    box-shadow: 0 0 #0000 !important;
  }
`;

export function DatePickerField(props: IInputFieldprops) {
  const [time, setTime] = React.useState(new Date());
  const [isOpen, setIsOpen] = React.useState(false);

  const { field, form, label, placeholder, disable } = props;
  const { setFieldValue } = form;

  const { name, value } = field;
  const { errors, touched } = form;
  const showError = errors[name] && touched[name];
  const errorString = errors[name] as string;

  const handleSelect = (nextTime: typeof time) => {
    setTime(nextTime);
  };

  const handleSelectDate = () => {
    setIsOpen(false);
    setFieldValue(name, time);
  };

  const formatDate = `${value?.getDate()}/${
    value?.getMonth() + 1
  }/${value?.getFullYear()}`;

  return (
    <div>
      <InputWrapStyled>
        {label && <h5 className="text-[#405275] leading-6 mb-0">{label}</h5>}
        <div
          className="flex justify-between cursor-pointer"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <InputStyled
            {...field}
            id={name}
            name={name}
            placeholder={placeholder}
            disabled={disable}
            value={value ? formatDate : ""}
            className="pointer-events-none"
          />

          <Image
            src="/statics/icons/Calendar.svg"
            alt="salon-img"
            width={36}
            height={36}
            className="mb-[12px]"
          />
        </div>
        {showError && (
          <h4 className="mt-[10px] text-[#FF0000] ml-[-18px] text-xl">
            {errorString}
          </h4>
        )}
      </InputWrapStyled>

      <ModalDatePicker
        value={time}
        max={new Date()}
        isOpen={isOpen}
        // showFooter= {false}
        showCaption={false}
        customHeader={
          <div>
            <h1 className="text-primary mb-[50px]">Vui lòng chọn ngày sinh</h1>
          </div>
        }
        dateConfig={[
          {
            type: "date",
            format: "DD",
            caption: "",
            step: 1,
          },
          {
            type: "month",
            format: "M",
            caption: "",
            step: 1,
          },
          {
            type: "year",
            format: "YYYY",
            caption: "",
            step: 1,
          },
        ]}
        onChange={handleSelect}
        customFooter={
          <div className="mt-[50px]">
            <PrimaryButton size="small" onClick={handleSelectDate}>
              <h3 className="ml-[18px] text-white mb-0">XÁC NHẬN ĐÚNG</h3>
            </PrimaryButton>
          </div>
        }
      />
    </div>
  );
}
