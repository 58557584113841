import React from "react";
import { Input } from "antd";
import { FieldProps } from "formik";
import styled from "styled-components";

export interface IInputFieldProps extends FieldProps {
  label?: string | JSX.Element;
  placeholder?: string;
  disable?: boolean;
  type?: string;
  onClick?: () => void;
  className?: string;
}

export const InputWrapStyled = styled.div`
  width: 796px;
  height: 105px;
  border: 0.75px solid #dee2ea;
  border-radius: 12px;
  margin-bottom: 40px;
  padding: 18px 30px;
  cursor: text;
`;
export const InputStyled = styled(Input)`
  border: none !important;
  padding: 0 !important;
  outline: none !important;
  margin-top: 4px;
  font-size: 1.875rem;
  color: #2a3345;
  font-weight: 700;

  &::placeholder {
    color: #808b9f;
  }

  &:focus {
    border: none !important;
    box-shadow: 0 0 #0000 !important;
  }
`;

export function InputField(props: IInputFieldProps) {
  const { field, form, label, placeholder, disable, type, onClick, className } =
    props;

  const { name } = field;
  const { errors, touched } = form;
  const showError = errors[name] && touched[name];
  const errorString = errors[name] as string;

  return (
    <InputWrapStyled onClick={onClick}>
      {label && <h5 className="text-[#405275] leading-6 mb-0">{label}</h5>}
      <InputStyled
        {...field}
        id={name}
        name={name}
        placeholder={placeholder}
        disabled={disable}
        type={type}
        className={className}
      />
      {showError && (
        <h4 className="mt-[10px] text-[#FF0000] ml-[-18px] text-xl">
          {errorString}
        </h4>
      )}
    </InputWrapStyled>
  );
}
