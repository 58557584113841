import React from "react";
import { Formik, Form, Field, FastField } from "formik";
import * as Yup from "yup";
import { InputField, InputPassWordField } from "@/forms";
import { PrimaryButton } from "@/components";
import { message } from "antd";
import { AuthService } from "@/services";
import { saveToken } from "@/utils";
import { useRouter } from "next/router";

export interface FormLoginValues {
  username: string;
  password: string;
}

const SchemaLoginForm = Yup.object({
  username: Yup.string().required("Xin vui lòng nhập email"),
  password: Yup.string().required("Xin vui lòng nhập mật khẩu"),
});

const initialValues: FormLoginValues = {
  username: "",
  password: "",
};

export function LoginForm() {
  const router = useRouter();

  const handleSubmit = async (values: FormLoginValues) => {
    try {
      const data = await AuthService.postLogin(values);
      const { token } = data;
      if (token) {
        saveToken(token,{ expires: 30 });
        router.push("/chon-chi-nhanh");
      }

      if (data.message === "CAN_NOT_USE_THIS_USER_IT_HERE") {
        return message.error("Tài khoản này không phải là tài khoản quản lý!");
      }
      if (data.message === "FAILD_TO_LOGIN") {
        return message.error("Tài khoản hoặc mật khẩu không chính xác!");
      }
    } catch (e) {
      console.log({ e });
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={SchemaLoginForm}
      >
        {(formikProps) => {
          const { values, errors, touched, isSubmitting } = formikProps;

          return (
            <Form>
              <FastField
                name="username"
                label="Email"
                placeholder="Mời nhập"
                component={InputField}
              />
              <Field
                name="password"
                label="Mật khẩu"
                placeholder="Mời nhập"
                component={InputPassWordField}
              />
              <PrimaryButton
                htmlType="submit"
                disabled={!(values.username && values.password)}
                size="large"
              >
                <h3 className="mb-0 font-bold text-white">
                  {isSubmitting ? "...Loading" : "ĐĂNG NHẬP"}
                </h3>
              </PrimaryButton>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
