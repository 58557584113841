import React from "react";
import { PrimaryButton } from "@/components";
import { SelectField } from "@/forms";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { SalonBranchModel } from "@/models";

export interface IInnitialValue {
  salonBranch: string;
}

export interface SalonBranchFormProps {
  options?: SalonBranchModel[];
  handleSubmit?: any;
}

export function SalonBranchForm(props: SalonBranchFormProps) {
  const { options, handleSubmit } = props;

  return (
    <Formik
      initialValues={{ salonBranch: "" }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        salonBranch: Yup.string().required("Vui lòng chọn chi nhánh."),
      })}
    >
      {(formikProps) => {
        const { values, errors, touched, isSubmitting } = formikProps;

        return (
          <Form>
            <Field
              name="salonBranch"
              label="Chọn chi nhánh"
              options={options}
              component={SelectField}
            />

            <PrimaryButton
              htmlType="submit"
              disabled={!values.salonBranch}
              size="large"
            >
              <h3 className="mb-0 font-bold text-white">HOÀN THÀNH</h3>
            </PrimaryButton>
          </Form>
        );
      }}
    </Formik>
  );
}
